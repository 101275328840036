@import '../../styles/customMediaQueries.css';

.root {
  /* Font */
  composes: h6 from global;
  color: var(--colorWhite);
  text-align: center;

  background-color: var(--colorBlueNote);

  /* Layout of a red dot */
  display: inline-block;
  width: auto;
  border-radius: 24px;
  font-size: 12px;
  height: 24px;
  width: 24px;
  line-height: 24px;
  position: relative;
  top: -2px;
  padding: 0;
  margin-left: 6px;

  /* Font margins off */
  margin-top: -5px;
  margin-bottom: 0;

}
