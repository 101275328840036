@import '../../../styles/customMediaQueries.css';

.root {
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

/* Container for side and content */
.container {
  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
  }
}

.main {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  padding: 24px;

  @media (--viewportLarge) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 48px 36px 82px 59px;
    background-color: var(--colorWhite);
    border-left-width: 0;
    border-left-style: solid;
    border-left-color: var(--colorGrey100);

    box-sizing: border-box;
    width: 70%;
    max-width: 800px;
    margin-right: calc(70% - 730px);
  }

 
}

.sideNav {
  /* Layout */
  display: flex;
  position: relative;

  box-sizing: content-box;
  border-top-width: 1px;
  border-top-color: var(--colorGrey100);
  border-top-style: solid;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadow);

  flex-direction: column;
  padding: 0 0 0 16px;
  margin: 0;
  width: auto;

  @media (--viewportLarge) {
    padding: 20px 0 0 20px;
    justify-items: right;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }



}

.tabs {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  min-height: 48px;
  padding-top: 2px;

  @media (--viewportMedium) {
    min-height: 48px;
  }

  @media (--viewportLarge) {
    min-height: auto;
    flex-direction: column;
    margin-top: 24px;
    padding-top: 0;
    position: sticky;
    top: 170px;
  }
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 0;
  }
}
