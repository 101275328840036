.root {
    margin-top: 30px;
    text-align: center;

    @media (--viewportMedium) {
        margin-top: 20px;
    }

    img {
        width: 120px;
    }
  }